<template>
  <cw-page
    :icon="title.icon"
    :class="`cw-business-loan-${wrapperClass}`"
    class="cw-business-loan-terms"
  >
    <template #hero-title>
      {{ hero.title }}
    </template>
    <validation-observer
      ref="observer"
      autocomplete="off"
      tag="v-form"
    >
      <v-row class="relative">
        <v-col
          cols="12"
          md="6"
        >
          <div
            class="
              cw-border-container
              cw-border-container--primary
            "
          >
            <h3 class="text-subtitle-1 font-weight-bold mb-1">
              <translate>Company Connection</translate>
            </h3>

            <p v-if="page !== 'businessLoanCompanySelection'">
              Henkilötiedoillasi ei löydy yritystä, jolle voisit hakea yrityslainaa.
            </p>

            <p v-if="page !== 'businessLoanCompanySelection'">
              <translate>
                Only a person in a deciding position in a company can apply for a corporate loan.
                In the absence of a sufficient business connection, the loan application is
                terminated.
              </translate>
            </p>

            <p v-if="page !== 'businessLoanCompanySelection'">
              Voit kuitenkin hakea Saldon tarjoamia kuluttajaluottoja eli Kulutusluottoa
              tai joustavaa 2000 euron Joustoluottoa.
            </p>

            <p
              v-if="companies.length === 1"
            >
              Henkilötiedoillasi löytyy oheisen yrityksen tiedot. Ole hyvä ja tarkista
              tiedot sekä roolisi yrityksessä. Jatkamalla vahvistat tiedot oikeiksi
              ja siirryt täyttämään yrityksen perustiedot.
            </p>

            <p
              v-else
            >
              Henkilötiedoillasi löytyy useamman yrityksen tiedot. Valitse ohesta yritys, jolle
              olet hakemassa lainaa ja tarkista tiedot sekä roolisi yrityksessä. Jatkamalla
              vahvistat valinnan ja siirryt täyttämään yrityksen perustiedot.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <cw-business-loan-company-list
            v-if="companies && companies.length"
            :form="form"
            :companies="companies"
          />
          <v-alert
            v-if="!$wait.waiting('COMPANY_CONNECTIONS') && !companies.length"
            border="left"
            class="cw-business-loan-no-company-connections-alert"
            type="error"
            dense
            text
          >
            <translate>No business connections found</translate>
          </v-alert>
        </v-col>
        <cw-form-loader v-if="$wait.waiting('COMPANY_CONNECTIONS')"/>
      </v-row>
    </validation-observer>

    <template v-if="!$wait.waiting('COMPANY_CONNECTIONS')" #actions>
      <v-spacer/>
      <v-btn
        v-if="page === 'businessLoanCompanySelection'"
        id="submit"
        :disabled="$wait.waiting('SUBMIT_FORM') || !companies.length"
        :loading="$wait.waiting('SUBMIT_FORM')"
        :dark="!$wait.waiting('SUBMIT_FORM') && companies.length > 0"
        color="purple-one darken-2"
        @click="
          submit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
      <v-btn
        v-else
        id="cw-business-loan-no-company-connections__link-to-saldo"
        href="https://www.saldo.com"
        color="purple-one darken-2"
        dark
        @click="$eventLogger.clickEvent($event)"
      >
        <translate>Apply for a consumer loan</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapState } from 'vuex';
import clone from 'lodash.clonedeep';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBusinessLoanCompanySelection',

  components: {
    'cw-business-loan-company-list':
      () => import('@fi/pages/steps/businessLoan/BusinessLoanCompanyList'),
    'validation-observer': ValidationObserver,
  },

  data: () => ({
    form: {
      selectedCompany: {},
    },
    page: '',
  }),

  computed: {
    ...mapState({
      companies: state => state.application.companyConnections,
      currentPage: state => state.application.initialData.currentPage,
      prefilledBusinessId: state => state.application.initialData.prefilledBusinessId,
    }),

    wrapperClass() {
      return this.page === 'businessLoanCompanySelection'
        ? 'company-selection' : 'no-company-connections';
    },

    hero() {
      return this.page === 'businessLoanCompanySelection'
        ? {
          title: this.$gettext('What company you represent?'),
        } : {
          title: this.$gettext('The application was suspended'),
        };
    },

    title() {
      return this.page === 'businessLoanCompanySelection'
        ? {
          icon: 'work',
        } : {
          icon: 'error',
        };
    },
  },

  beforeMount() {
    this.$wait.start('COMPANY_CONNECTIONS');
    this.page = clone(this.currentPage);
  },

  async mounted() {
    await this.getCompanyConnections();
    this.$wait.end('COMPANY_CONNECTIONS');
    if (this.companies && this.companies.some(company => company.valid)) {
      this.form.selectedCompany = this.companies
        .filter(company => company.valid)
        .find(company => company.businessId === this.prefilledBusinessId)
         || this.companies.find(company => company.valid);
    }
  },

  methods: {
    ...mapActions({
      getCompanyConnections: 'application/getCompanyConnections',
      submitForm: 'application/submit',
    }),

    async submit() {
      this.$wait.start('SUBMIT_FORM');
      const routineParams = {
        uri: 'executeRoutine',
        routine: 'Submit Company Selection',
      };

      try {
        this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationClick' } });
        this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationSubmit' } });

        DataLayer.onValidationClick();

        await this.submitForm({
          formParams: {
            businessId: this.form.selectedCompany.businessId,
          },
          routineParams,
        });
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>

<style lang="scss">
.cw-business-loan-company-selection,
.cw-business-loan-no-company-connections {
  form > div {
    min-height: 100px;
  }
}
</style>
